import React, { useState } from "react";
import LinearButton from "../../../Button";
const Carousel = ({ contextCards, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const card = contextCards[currentIndex];
  const { card_title, card_display_text, image_display_position, image_url } =
    card;

  const handleNext = () => {
    if (currentIndex < contextCards.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="h-full">
      <div className="h-full relative">
        {/* Card Title */}
        <header>
          <div className="text-center">
            <h4
              style={{
                color: "var(--primary-button-background)",
              }}
            >
              {card_title}
            </h4>
          </div>
        </header>

        {/* Image and Image Text */}
        <main>
          <div className="flex justify-between mt-10 space-x-4">
            {image_url && (
              <div
                style={{
                  flex: "1" /* This is equivalent to flex-grow: 1; */,
                  width: "300px",
                  height: "196px",
                  border: "1px solid #ccc",
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={image_url}
                  alt={card_title}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            )}
            <div
              className="flex justify-center items-center"
              style={{
                flex: "1",
              }}
            >
              <p style={{ color: "var(--black)" }}>{card_display_text}</p>
            </div>
          </div>
        </main>

        {/* Prev and Next Button */}
        <footer className="absolute bottom-0 w-full">
          <div className="flex w-full justify-between space-x-12 mt-8">
            <div>
              <LinearButton
                onClick={handlePrevious}
                disabled={currentIndex === 0}
                text="Previous"
              />
            </div>
            <div>
              {currentIndex === contextCards.length - 1 ? (
                <LinearButton onClick={onClose} text="Close" />
              ) : (
                <LinearButton
                  onClick={handleNext}
                  disabled={currentIndex === contextCards.length - 1}
                  text="Next"
                />
              )}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Carousel;
