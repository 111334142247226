import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { API } from "../../../../api";
import "./index.css";
import { useLocation, useHistory } from "react-router-dom";

// Components
import MCQ from "./MCQ";
import FillBlanks from "./FillBlanks";
import ProgressBar from "../../../../components/Activity/ProgressBar";
import Order from "./Order";
import FlashCard from "../../../ChallengeNew/FlashCard";
import NewModal from "../../../../components/NewModal";
import LinearButton from "../../../../components/Button";
import CancelIcon from "@material-ui/icons/Clear";

const AdminPreviewActivity = (props) => {
  const [activity, setActivity] = useState();
  const [questionList, setQuestionList] = useState([]);
  const [sequence, setSequence] = useState(0);
  const [maxSequence, setMaxSequence] = useState(1);
  const [bgcolor, setBgColor] = useState("#FFFFFF");
  const [message, setMessage] = useState("");
  const [snippetShow, setSnippetShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [response, setResponse] = useState();

  const [showQuit, setShowQuit] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const goal_id = location.state?.goal_id;
  const program_name = location.state?.program_name;
  const { referrer } = location.state || {};

  const [questionData, setQuestionData] = useState({
    id: "",
    stem: "",
    options: [],
    comment: "",
    type: "",
    question_image_url: "",
  });

  const { activity_id } = props.match.params;

  function updateData() {
    setQuestionData({
      stem: questionList[sequence - 1]?.question?.stem,
      comment: questionList[sequence - 1]?.question?.comment,
      options: questionList[sequence - 1]?.question?.components,
      type: questionList[sequence - 1]?.question?.type,
      id: questionList[sequence - 1]?.question?.id,
      question_image_url:
        questionList[sequence - 1]?.question?.question_image_url,
    });
  }

  useEffect(() => {
    async function fetchData() {
      const { data } = await API.get(
        "strapi/get-questions",
        `null/${activity_id}`
      );
      setQuestionList(data);
      setActivity(data);
      setMaxSequence(data.length);
      setBgColor("#0ACC81");
      setSnippetShow(true);
      setMessage("CORRECT ANSWER");
      setSequence(1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setBgColor("#0ACC81");
    setSnippetShow(true);
    setMessage("CORRECT ANSWER");
    updateData();
  }, [sequence]);

  const _nextQuestion = async (id) => {
    if (sequence < maxSequence) {
      setSequence(sequence + 1);
    }
  };

  const handleSubmit = async (e) => {
    if (e.target.innerHTML === "Next" || e.target.innerHTML === "Check") {
      setResponse();
      _nextQuestion();
    } else if (e.target.innerHTML === "Finish") {
      setMaxSequence(maxSequence - 1);
      e.target.innerHTML = "Finish";
      history.push(referrer, {
        goal_id,
        program_name,
      });
    }
  };

  const displayQuestion = (type) => {
    if (type === "mcq")
      return (
        <MCQ
          options={questionData.options}
          id={questionData.id}
          isUserCorrect={response?.isUserCorrect}
          correctAnswer={response?.correctAnswer}
        />
      );
    else if (type === "fill_blanks") {
      return <FillBlanks />;
    } else if (type === "order") {
      return <Order id={questionData.id} options={questionData.options} />;
    } else if (type === "flashcard") {
      return (
        <>
          <FlashCard
            image={questionData.question_image_url}
            id={questionData.id}
          />
        </>
      );
    }
    // } else if (type === "match") {
    //   const optionList = activity.questions[sequence - 1].options;
    //   const optionKeys = optionList.filter((option) => {
    //     return option.is_key;
    //   });
    //   const optionValues = optionList.filter((option) => {
    //     return !option.is_key;
    //   });
    //   return (
    //     <Match
    //       id={activity.questions[sequence - 1].id}
    //       optionKeys={optionKeys}
    //       optionValues={optionValues}
    //       sendResponse={getResponse}
    //     />
    //   );
    // }
  };

  // const _handleShow = () => {
  //   setReadingShow(!readingShow);
  // };

  if (!activity || !questionList) return null;
  //if (!goal_id) return null;

  // if (readingShow) {
  //   return <ActivityReading reading={reading} handleShow={_handleShow} />;
  // }

  return (
    <>
      <Container maxWidth="md">
        <div style={{ marginTop: "50px" }}>
          <div className="flex flex-row justify-center items-center">
            <ProgressBar
              bgcolor={"#ef6c00"}
              completed={parseInt(((sequence - 1) / maxSequence) * 100)}
            />

            <CancelIcon
              className="cursor-pointer mt-4"
              onClick={() => {
                setShowQuit(true);
              }}
            />
          </div>
          {questionList[sequence - 1]?.question?.type === "flashcard" ? (
            <h5>{questionData.stem}</h5>
          ) : (
            <h5 className="text-center">{questionData.stem}</h5>
          )}

          <div style={{ marginBottom: "25px" }}>
            {displayQuestion(questionData.type)}
          </div>
        </div>
        {questionData.type === "flashcard" && (
          <div> {questionData.comment}</div>
        )}
        <div
          className="body-medium-2 text-center z-10"
          style={{
            display:
              snippetShow && questionData.type !== "flashcard"
                ? "block"
                : "none",
            background: "#FDF9F0",
            overflow: "auto",
            height: "auto",
          }}
        >
          <div
            className="quarter-circle-top-right z-10"
            style={{ backgroundColor: bgcolor }}
          ></div>
          <div className="subtitle text-center my-4">{message}</div>
          <div className="text-center px-4">{questionData.comment}</div>
          <div
            className="quarter-circle-bottom-left"
            style={{ backgroundColor: bgcolor }}
          ></div>
        </div>
        {/* Footer */}
        <div className="flex flex-row justify-between gap-16 my-8 z-10">
          {/* Back Button */}
          <div>
            {sequence > 1 ? (
              <button
                onClick={() => setSequence((prev) => prev - 1)}
                // disabled={disableButton}
                className="text-white px-8 py-4 w-36 rounded-2xl text-xl shadow-lg"
                style={{
                  backgroundColor: "#003366",
                }}
              >
                Back
              </button>
            ) : (
              <div></div>
            )}
          </div>
          {/* Forward Button */}
          <div>
            <button
              onClick={handleSubmit}
              className="text-white px-8 py-4 w-36 rounded-2xl text-xl shadow-lg"
              style={{
                backgroundColor: " #003366",
              }}
            >
              {response ||
              questionList[sequence - 1]?.question?.type === "flashcard"
                ? sequence === activity.length
                  ? "Finish"
                  : "Next"
                : "Next"}
              {/* {true
                ? sequence === activity.length
                  ? "Finish"
                  : "Next"
                : "Check"} */}
            </button>
          </div>
        </div>
      </Container>
      {showQuit && (
        <NewModal
          body={
            <div>
              <div className="flex flex-row justify-center items-center">
                <h5>Are you sure to quit?</h5>
              </div>
            </div>
          }
          footer={
            <div className="flex flex-row justify-center gap-16 items-center">
              <LinearButton
                text="Yes"
                onClick={() => {
                  history.push(referrer, {
                    goal_id,
                    program_name,
                  });
                }}
              />
              <LinearButton
                text="No"
                onClick={() => {
                  setShowQuit(false);
                }}
              />
            </div>
          }
          hasCloseButton={false}
        />
      )}
    </>
  );
};

export default AdminPreviewActivity;
