import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Skeleton,
  Typography,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { API } from "../../api";
import ActivityRowBox from "../DashboardComponents/ActivityRowBox";
import { useHistory } from "react-router-dom";

const SearchEngine = ({ open, setOpen }) => {
  const [input, setInput] = useState(""); // Tracks the search input
  const [isLoading, setIsLoading] = useState(false); // Simulates API loading
  const [results, setResults] = useState([]); // Placeholder for search results

  const history = useHistory();
  const goToActivity = (result) => {
    history.push(`/activity/id/${result.id}`, {
      goal_id: result.goal_id,
      activity_name: result.name,
    });
  };

  const handleInputChange = (e) => {
    setIsLoading(true);
    const value = e.target.value.trim();
    setInput(value);
  };

  useEffect(() => {
    const fetchResults = async () => {
      setIsLoading(true);
      if (input === "") {
        setResults([]); // Clear results when input is empty
        setIsLoading(false);
        return;
      }

      try {
        const { data } = await API.get("search/completed-activites", input);
        setResults(data || []);
        // console.log("data", data);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    };

    const debounce = setTimeout(() => {
      fetchResults();
    }, 1000);

    return () => clearTimeout(debounce);
  }, [input]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(open)}
      fullWidth
      maxWidth="sm"
      className="divide-y-2"
    >
      <div className="flex flex-col divide-y-2">
        {/* Modal Title */}
        <div>
          <DialogTitle>
            <div className="flex items-center gap-2 relative">
              {/* Search Icon */}
              <div>
                <svg
                  className="w-8 h-8"
                  style={{ color: "#2288EE", fill: "#2288EE" }}
                  viewBox="0 0 20 20"
                >
                  <SearchIcon />
                </svg>
              </div>

              {/* Input Field */}
              <div className="flex-grow">
                <TextField
                  fullWidth
                  placeholder="Search Completed Activities..."
                  value={input}
                  onChange={handleInputChange}
                  autoFocus
                  variant="standard"
                  InputProps={{
                    disableUnderline: true, // Removes underline for a cleaner look
                  }}
                  className="bg-transparent" // Ensures a seamless background
                />
              </div>

              {/* Close Button */}
              <div className="absolute right-2">
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setOpen(false);
                    setResults([]);
                    setInput("");
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
        </div>
        {/* Modal Content */}
        <div>
          <DialogContent>
            {/* Results Area */}
            <Box
              mt={2}
              sx={{
                maxHeight: "300px",
                overflowY: "auto",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              {/* Skeleton Loading */}
              {isLoading &&
                Array.from({ length: 5 }).map((_, i) => (
                  <Skeleton key={i} variant="text" height={40} />
                ))}

              {/* Display Results */}
              {!isLoading &&
                results.map((result) => (
                  <Typography
                    key={result.id}
                    variant="body1"
                    sx={{ padding: "8px" }}
                  >
                    <ActivityRowBox
                      missed={false}
                      completed={true}
                      enabled={true}
                      message={result.name}
                      id={result.id}
                      key={result.id}
                      onClick={() => goToActivity(result)}
                    />
                  </Typography>
                ))}

              {/* No Results Placeholder */}
              {!isLoading && results.length === 0 && (
                <Typography variant="body2" color="text.secondary">
                  No results found.
                </Typography>
              )}
            </Box>
          </DialogContent>
        </div>
      </div>
    </Dialog>
  );
};

export default SearchEngine;
