import React, { useEffect, useState, useRef } from "react";
import { API } from "../../../api";
import "./index.css";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import hourglass from "../../../assests/icons/hourglass.svg";
import NewModal from "../../../components/NewModal";
import LinearButton from "../../../components/Button";
import axios from "axios";
import FileDownload from "js-file-download";

let DEFAULT_DATE = "2022-02-02";
let DEFAULT_START_TIME = "10:00";
let DEFAULT_END_TIME = "11:00";

const GetChallenge = (props) => {
  const [challenge, setChallenge] = useState();
  const [scheduleData, setScheduleData] = useState({
    date: DEFAULT_DATE,
    start_time: DEFAULT_START_TIME,
    end_time: DEFAULT_END_TIME,
  });

  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState();
  const [showModalCloseButton, setShowModalCloseButton] = useState(false);
  const [modalButtonState, setModalButtonState] = useState(0); // 0 for hidden, 1 for schedule api call, 2 for return to dashboard

  const history = useHistory();
  const location = useLocation();

  const { challenge_id } = props.match.params;
  const goal_id = location.state?.goal_id;
  const completedDate = location.state?.completedDate;
  const completed = location.state?.completed;

  const getStartHour = (date) => {
    date.setHours(date.getHours() + 1);
    date.setMinutes(0, 0, 0);
    return date;
  };
  const getEndHour = (date) => {
    date.setHours(date.getHours() + 2);
    date.setMinutes(0, 0, 0);
    return date;
  };

  useEffect(() => {
    const today = new Date();
    const startHour = getStartHour(new Date());
    const endHour = getEndHour(new Date());

    DEFAULT_DATE = today.toISOString().split("T")[0];
    DEFAULT_START_TIME =
      startHour.toTimeString().split(":")[0].toString() + ":00";
    DEFAULT_END_TIME = endHour.toTimeString().split(":")[0].toString() + ":00";
    setScheduleData({
      date: DEFAULT_DATE,
      start_time: DEFAULT_START_TIME,
      end_time: DEFAULT_END_TIME,
    });
  }, []);

  useEffect(() => {
    async function checkChallengeActive() {
      const { data, status } = await API.get(
        "user-data/active-challenge",
        challenge_id
      );
      if (status === 200 && data[0] !== null)
        history.replace(`/challenge/complete/${challenge_id}`, {
          user_challenge_id: data[0].id,
          goal_id
        });
    }
    async function getChallenge() {
      const { data } = await API.get(`challenge/goal_id/${goal_id}/id`, challenge_id);;
      if (Array.isArray(data.challenge_steps) && data.challenge_steps.length > 0) {
        history.push(`/challenge/id/new/${challenge_id}`, {
          goal_id,
          completedDate,
          completed
        });
        return;
      }
      setChallenge(data);
    }
    getChallenge();
    checkChallengeActive();
  }, []);

  useEffect(() => {
    DEFAULT_END_TIME = scheduleData.end_time;
  }, [scheduleData]);

  const handleChange = (e) => {
    setScheduleData({ ...scheduleData, [e.target.name]: e.target.value });
    switch (e.target.name) {
      case "date":
        DEFAULT_DATE = e.target.value;
        break;
      case "start_time":
        DEFAULT_START_TIME = e.target.value;
        break;
      case "end_time":
        DEFAULT_END_TIME = e.target.value;
        break;
      default: break;
    }
  };

  const _handleSubmit = () => {
    setShowModalCloseButton(false);
    if (
      !scheduleData.date ||
      !scheduleData.start_time ||
      !scheduleData.end_time
    ) {
      setShowModal(true);
      setModalText("Enter all the values for scheduling...");
      setShowModalCloseButton(true);
    } else {
      setShowModal(true);
      setModalText(
        `${scheduleData.start_time} to ${scheduleData.end_time}: Schedule Time for ${challenge.name}`
      );
      setShowModalCloseButton(true);
      setModalButtonState(1);
    }
  };
  const scheduleChallenge = async () => {
    setModalButtonState(0);
    setModalText("Please wait...");
    setShowModalCloseButton(false);
    const dateSplit = scheduleData.date.split("-");
    const year = dateSplit[0];
    const monthIndex = dateSplit[1] - 1;
    const day = dateSplit[2];
    const startTimeSplit = scheduleData.start_time.split(":");
    const startHour = startTimeSplit[0];
    const startMinutes = startTimeSplit[1];
    const endTimeSplit = scheduleData.end_time.split(":");
    const endHour = endTimeSplit[0];
    const endMinutes = endTimeSplit[1];

    const body = {
      date: new Date(),
      start_time: new Date(year, monthIndex, day, startHour, startMinutes).toISOString(),
      end_time: new Date(year, monthIndex, day, endHour, endMinutes).toISOString()
    }
    const { data, status } = await API.post(
      `challenge/schedule/${challenge_id}`,
      body
    );
    if (status === 200) {
      setModalText("Your challenge has been scheduled!");
      setModalButtonState(2);
    } else {
      setModalText(data.error.message);
    }
  };

  const getTemplate = () => {
    axios
      .get(challenge.template_url, {
        responseType: "blob",
      })
      .then((response) => {
        FileDownload(response.data, `${challenge.name}.doc`);
      });
  };

  // const addEventInCalendar = async (calendar) => {
  //   const eventStartTime =
  //     scheduleData.date + "T" + scheduleData.start_time + ":00";
  //   const eventEndTime =
  //     scheduleData.date + "T" + scheduleData.end_time + ":00";

  //   const event = {
  //     summary: challenge?.name,
  //     description: challenge?.description,
  //     start: {
  //       dateTime: eventStartTime,
  //       timeZone: "Asia/Kolkata",
  //     },
  //     end: {
  //       timeZone: "Asia/Kolkata",
  //       dateTime: eventEndTime,
  //     },
  //   };
  //   const request = calendar.events.insert({
  //     calendarId: "primary",
  //     resource: event,
  //   });
  //   request.execute((event) => {
  //     setModalButtonState(2);
  //     scheduleChallenge();
  //   });
  // };

  // const _handleGoogleSubmit = async () => {
  //   const gapi = window.gapi;
  //   const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  //   const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  //   const discoveryDocs = [
  //     "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  //   ];
  //   const scope = "https://www.googleapis.com/auth/calendar.events";

  //   await gapi.client.init({
  //     apiKey,
  //     clientId,
  //     discoveryDocs,
  //     scope,
  //   });

  //   await gapi.client.load("calendar", "v3");

  //   const authInstance = await gapi.auth2.getAuthInstance();
  //   if (!authInstance.isSignedIn.get()) {
  //     await authInstance.signIn();
  //   }
  //   await addEventInCalendar(gapi.client.calendar);
  // };

  if (!challenge) return null;

  return (
    <div className="flex flex-col items-center p-4">
      <h5 className="my-8">{challenge?.name}</h5>
      <div
        className="text-xl px-8 md:px-24 mb-4"
        style={{ color: "#2288EE", whiteSpace: "pre-wrap" }}
      >
        {challenge?.description}
      </div>
      <div
        className=""
        style={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 4px rgba(0, 51, 102, 0.1)",
          borderRadius: "10px",
        }}
      >
        <div className="flex flex-col md:flex-row flex-wrap gap-16 mt-8 p-8 px-16 pt-4">
          <div className="flex flex-col body-small-1 ">
            <label>Select Date</label>
            <input
              className="input-box"
              type="date"
              name="date"
              value={DEFAULT_DATE}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col body-small-1">
            <label>Start Time</label>
            <input
              className="input-box"
              type="time"
              name="start_time"
              value={DEFAULT_START_TIME}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col body-small-1">
            <label>End Time</label>
            <input
              type="time"
              className="input-box"
              name="end_time"
              value={DEFAULT_END_TIME}
              onChange={handleChange}
            />
          </div>
        </div>
        {challenge.template_url && (
          <div className="flex mb-8 mt-2 justify-center">
            <LinearButton
              text="Get Template"
              size="extra-large"
              onClick={getTemplate}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col sm:flex-row sm:gap-4">
        <button
          className="text-white py-4 px-8 mt-8 bottom-10 right-10"
          style={{ background: "#003366", borderRadius: "10px" }}
          onClick={_handleSubmit}
        >
          Confirm
        </button>
      </div>
      <div>
        {showModal && (
          <NewModal
            body={
              <div className="flex flex-col gap-4 items-center justify-center">
                <div className="mb-4">
                  <img src={hourglass} alt="hourglass" />
                </div>
                <div className="text-center px-8">
                  <h5>{modalText}</h5>
                </div>
                <div>
                  {modalButtonState === 1 && (
                    <div className="flex flex-col justify-between gap-4 items-center w-full">
                      <LinearButton
                        size="extra-large"
                        width="15rem"
                        text="Add to Tasks"
                        onClick={scheduleChallenge}
                      />
                      {/* <LinearButton
                        size="extra-large"
                        width="15rem"
                        text="Add to Google Calendar"
                        onClick={async () => {
                          await _handleGoogleSubmit();
                        }}
                      /> */}
                    </div>
                  )}
                  {modalButtonState === 2 && (
                    <LinearButton
                      text="Return to dashboard"
                      width="15rem"
                      onClick={() => history.push("/user/dashboard")}
                    />
                  )}
                </div>
              </div>
            }
            hasCloseButton={showModalCloseButton}
            onClose={() => setShowModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default GetChallenge;
