import { Tooltip } from "@material-ui/core";
import ExploreIcon from "@mui/icons-material/Explore";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "../../../api";
import Table from "../../Table";

const ProgramEnrolledTable = () => {
  const [enrolledGoals, setEnrolledGoals] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  const history = useHistory();
  const location = useLocation();

  const ExploreButton = ({ goal_id, static_day, program_name }) => {
    return (
      <button
        onClick={() => {
          const referrer = location.pathname;
          history.push("/user/program-schedule", {
            goal_id: goal_id,
            static_day,
            program_name,
            referrer: referrer,
          });
        }}
      >
        <Tooltip title="Explore Program Schedule" arrow placement="top">
          <svg
            className="w-8 h-8"
            style={{ color: "#2288EE", fill: "#2288EE" }}
            viewBox="0 0 20 20"
          >
            <ExploreIcon />
          </svg>
        </Tooltip>
      </button>
    );
  };

  async function getEnrolledGoals() {
    const { data, status } = await API.get("goal", "enrolled-goals");
    if (status === 200) {
      setEnrolledGoals(data);
    }
  }

  useEffect(() => {
    if (enrolledGoals.length === 0) return;
    const tableData = [];
    for (const goal of enrolledGoals) {
      if (!(goal?.quitted || goal?.paused || goal?.completed)) {
        const programName = goal?.goal?.program?.name;
        const staticDay = goal?.static_schedule_day_number;
        const totalDays = goal?.goal?.program?.number_of_days;
        const dayNumber = `${staticDay} of ${totalDays}`;
        const element = [
          programName,
          dayNumber,
          <div className="flex flex-row gap-4">
            {ExploreButton({
              goal_id: goal?.goal_id,
              static_day: staticDay,
              program_name: programName,
            })}
          </div>,
        ];
        tableData.push(element);
      }
    }
    setTableHeader(["Program", "Day", "Explore"]);
    setTableBody(tableData);
  }, [enrolledGoals]);

  useEffect(() => {
    getEnrolledGoals();
  }, []);
  return (
    <div className="m-4 p-4 column" style={{ backgroundColor: "#FFFFFF" }}>
      <div className="flex mb-2 flex-row justify-between">
        <h6>Enrolled Programs</h6>
      </div>
      {enrolledGoals.length === 0 && (
        <div className="flex flex-row justify-center mt-8 items-center">
          Enoll into program to start
        </div>
      )}
      {enrolledGoals.length > 0 && (
        <Table header={tableHeader} body={tableBody} />
      )}
    </div>
  );
};

export default ProgramEnrolledTable;
